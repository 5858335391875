import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { ICustomerProposal } from '../interfaces/customer-proposal';
import { ICustomer } from '../interfaces/customer';
import { ICustomerLocation } from '../interfaces/customer-location';
import { IOffice } from '../interfaces/office';
import { ICustomerProposalStatus } from '../interfaces/customer-proposal-status';

export interface ICustomerProposalDynamicControlsParameters {
    formGroup?: string;
    customers?: ICustomer[];
    offices?: IOffice[];
    customerLocations?: ICustomerLocation[];
    jobLocations?: ICustomerLocation[];
    statuses?: ICustomerProposalStatus[];
}

export class CustomerProposalDynamicControls {

    formGroup: string;
    customers?: ICustomer[];
    offices?: IOffice[];
    customerLocations?: ICustomerLocation[];
    jobLocations?: ICustomerLocation[];
    statuses?: ICustomerProposalStatus[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customerproposal?: ICustomerProposal, additionalParameters?: ICustomerProposalDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'CustomerProposal';
        this.customers = additionalParameters && additionalParameters.customers || undefined;
        this.offices = additionalParameters && additionalParameters.offices || undefined;
        this.customerLocations = additionalParameters && additionalParameters.customerLocations || undefined;
        this.jobLocations = additionalParameters && additionalParameters.jobLocations || undefined;
        this.statuses = additionalParameters && additionalParameters.statuses || undefined;

        this.Form = {
            AltPhone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Alt Phone',
                name: 'AltPhone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.customerproposal && this.customerproposal.hasOwnProperty('AltPhone') && this.customerproposal.AltPhone != null ? this.customerproposal.AltPhone.toString() : '',
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customerproposal && this.customerproposal.CustomerId || null,
            }),
            CustomerJobDescription: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Job Description',
                name: 'CustomerJobDescription',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.WYSIWYG,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.customerproposal && this.customerproposal.hasOwnProperty('CustomerJobDescription') && this.customerproposal.CustomerJobDescription != null ? this.customerproposal.CustomerJobDescription.toString() : '',
            }),
            CustomerLocationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Location',
                name: 'CustomerLocationId',
                options: this.customerLocations,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customerproposal && this.customerproposal.CustomerLocationId || null,
            }),
            CustomerName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Name',
                name: 'CustomerName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.customerproposal && this.customerproposal.hasOwnProperty('CustomerName') && this.customerproposal.CustomerName != null ? this.customerproposal.CustomerName.toString() : '',
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerproposal && this.customerproposal.DateCreated || null,
            }),
            DateOfProposal: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Of Proposal',
                name: 'DateOfProposal',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.customerproposal && this.customerproposal.DateOfProposal || null,
            }),
            EmailAddress: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email Address',
                name: 'EmailAddress',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.customerproposal && this.customerproposal.hasOwnProperty('EmailAddress') && this.customerproposal.EmailAddress != null ? this.customerproposal.EmailAddress.toString() : '',
            }),
            HeatLossNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Heat Loss Notes',
                name: 'HeatLossNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerproposal && this.customerproposal.hasOwnProperty('HeatLossNotes') && this.customerproposal.HeatLossNotes != null ? this.customerproposal.HeatLossNotes.toString() : '',
            }),
            IncludeWarranty: new DynamicField({
                formGroup: this.formGroup,
                label: 'Include Warranty',
                name: 'IncludeWarranty',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerproposal && this.customerproposal.hasOwnProperty('IncludeWarranty') && this.customerproposal.IncludeWarranty != null ? this.customerproposal.IncludeWarranty : true,
            }),
            InternalNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Internal Notes',
                name: 'InternalNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerproposal && this.customerproposal.hasOwnProperty('InternalNotes') && this.customerproposal.InternalNotes != null ? this.customerproposal.InternalNotes.toString() : '',
            }),
            JobLocationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Job Location',
                name: 'JobLocationId',
                options: this.jobLocations,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customerproposal && this.customerproposal.JobLocationId || null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(150) ],
                validators: { 'required': true, 'maxlength': 150 },
                value: this.customerproposal && this.customerproposal.hasOwnProperty('Name') && this.customerproposal.Name != null ? this.customerproposal.Name.toString() : '',
            }),
            OfficeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Office',
                name: 'OfficeId',
                options: this.offices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customerproposal && this.customerproposal.OfficeId || null,
            }),
            PrimaryPhone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Primary Phone',
                name: 'PrimaryPhone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(20) ],
                validators: { 'required': true, 'maxlength': 20 },
                value: this.customerproposal && this.customerproposal.hasOwnProperty('PrimaryPhone') && this.customerproposal.PrimaryPhone != null ? this.customerproposal.PrimaryPhone.toString() : '',
            }),
            ProposalSignatureDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Proposal Signature Date',
                name: 'ProposalSignatureDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerproposal && this.customerproposal.ProposalSignatureDate || null,
            }),
            ProposalSignatureImage: new DynamicField({
                formGroup: this.formGroup,
                label: 'Proposal Signature Image',
                name: 'ProposalSignatureImage',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.customerproposal && this.customerproposal.hasOwnProperty('ProposalSignatureImage') && this.customerproposal.ProposalSignatureImage != null ? this.customerproposal.ProposalSignatureImage.toString() : '',
            }),
            SalesPersonId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sales Person',
                name: 'SalesPersonId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.customerproposal && this.customerproposal.SalesPersonId || null,
            }),
            StatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Status',
                name: 'StatusId',
                options: this.statuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerproposal && this.customerproposal.StatusId || 1,
            }),
            StrivenSalesOrderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Striven Sales Order',
                name: 'StrivenSalesOrderId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerproposal && this.customerproposal.StrivenSalesOrderId || null,
            }),
            UniqueIdentifier: new DynamicField({
                formGroup: this.formGroup,
                label: 'Unique Identifier',
                name: 'UniqueIdentifier',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerproposal && this.customerproposal.hasOwnProperty('UniqueIdentifier') && this.customerproposal.UniqueIdentifier != null ? this.customerproposal.UniqueIdentifier.toString() : '',
            }),
            UsesFinancing: new DynamicField({
                formGroup: this.formGroup,
                label: 'Uses Financing',
                name: 'UsesFinancing',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerproposal && this.customerproposal.hasOwnProperty('UsesFinancing') && this.customerproposal.UsesFinancing != null ? this.customerproposal.UsesFinancing : false,
            }),
        };

        this.View = {
            AltPhone: new DynamicLabel({
                label: 'Alt Phone',
                value: this.customerproposal && this.customerproposal.hasOwnProperty('AltPhone') && this.customerproposal.AltPhone != null ? this.customerproposal.AltPhone.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CustomerId: new DynamicLabel({
                label: 'Customer',
                value: getMetaItemValue(this.customers as unknown as IMetaItem[], this.customerproposal && this.customerproposal.hasOwnProperty('CustomerId') ? this.customerproposal.CustomerId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CustomerJobDescription: new DynamicLabel({
                label: 'Customer Job Description',
                value: this.customerproposal && this.customerproposal.hasOwnProperty('CustomerJobDescription') && this.customerproposal.CustomerJobDescription != null ? this.customerproposal.CustomerJobDescription.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            }),
            CustomerLocationId: new DynamicLabel({
                label: 'Customer Location',
                value: getMetaItemValue(this.customerLocations as unknown as IMetaItem[], this.customerproposal && this.customerproposal.hasOwnProperty('CustomerLocationId') ? this.customerproposal.CustomerLocationId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CustomerName: new DynamicLabel({
                label: 'Customer Name',
                value: this.customerproposal && this.customerproposal.hasOwnProperty('CustomerName') && this.customerproposal.CustomerName != null ? this.customerproposal.CustomerName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.customerproposal && this.customerproposal.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateOfProposal: new DynamicLabel({
                label: 'Date Of Proposal',
                value: this.customerproposal && this.customerproposal.DateOfProposal || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            EmailAddress: new DynamicLabel({
                label: 'Email Address',
                value: this.customerproposal && this.customerproposal.hasOwnProperty('EmailAddress') && this.customerproposal.EmailAddress != null ? this.customerproposal.EmailAddress.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            HeatLossNotes: new DynamicLabel({
                label: 'Heat Loss Notes',
                value: this.customerproposal && this.customerproposal.hasOwnProperty('HeatLossNotes') && this.customerproposal.HeatLossNotes != null ? this.customerproposal.HeatLossNotes.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            }),
            IncludeWarranty: new DynamicLabel({
                label: 'Include Warranty',
                value: this.customerproposal && this.customerproposal.hasOwnProperty('IncludeWarranty') && this.customerproposal.IncludeWarranty != null ? this.customerproposal.IncludeWarranty : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            InternalNotes: new DynamicLabel({
                label: 'Internal Notes',
                value: this.customerproposal && this.customerproposal.hasOwnProperty('InternalNotes') && this.customerproposal.InternalNotes != null ? this.customerproposal.InternalNotes.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            }),
            JobLocationId: new DynamicLabel({
                label: 'Job Location',
                value: getMetaItemValue(this.jobLocations as unknown as IMetaItem[], this.customerproposal && this.customerproposal.hasOwnProperty('JobLocationId') ? this.customerproposal.JobLocationId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Name: new DynamicLabel({
                label: 'Name',
                value: this.customerproposal && this.customerproposal.hasOwnProperty('Name') && this.customerproposal.Name != null ? this.customerproposal.Name.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            OfficeId: new DynamicLabel({
                label: 'Office',
                value: getMetaItemValue(this.offices as unknown as IMetaItem[], this.customerproposal && this.customerproposal.hasOwnProperty('OfficeId') ? this.customerproposal.OfficeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            PrimaryPhone: new DynamicLabel({
                label: 'Primary Phone',
                value: this.customerproposal && this.customerproposal.hasOwnProperty('PrimaryPhone') && this.customerproposal.PrimaryPhone != null ? this.customerproposal.PrimaryPhone.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ProposalSignatureDate: new DynamicLabel({
                label: 'Proposal Signature Date',
                value: this.customerproposal && this.customerproposal.ProposalSignatureDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            ProposalSignatureImage: new DynamicLabel({
                label: 'Proposal Signature Image',
                value: this.customerproposal && this.customerproposal.hasOwnProperty('ProposalSignatureImage') && this.customerproposal.ProposalSignatureImage != null ? this.customerproposal.ProposalSignatureImage.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            SalesPersonId: new DynamicLabel({
                label: 'Sales Person',
                value: this.customerproposal && this.customerproposal.SalesPersonId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            StatusId: new DynamicLabel({
                label: 'Status',
                value: getMetaItemValue(this.statuses as unknown as IMetaItem[], this.customerproposal && this.customerproposal.hasOwnProperty('StatusId') ? this.customerproposal.StatusId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            StrivenSalesOrderId: new DynamicLabel({
                label: 'Striven Sales Order',
                value: this.customerproposal && this.customerproposal.StrivenSalesOrderId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            UniqueIdentifier: new DynamicLabel({
                label: 'Unique Identifier',
                value: this.customerproposal && this.customerproposal.hasOwnProperty('UniqueIdentifier') && this.customerproposal.UniqueIdentifier != null ? this.customerproposal.UniqueIdentifier.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            UsesFinancing: new DynamicLabel({
                label: 'Uses Financing',
                value: this.customerproposal && this.customerproposal.hasOwnProperty('UsesFinancing') && this.customerproposal.UsesFinancing != null ? this.customerproposal.UsesFinancing : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
